/**
 * 签到活动
 */
import request, { MarkcoinRequest } from '@/plugins/request'
import {
  GetSignInInfoRequest,
  GetSignInInfoResp,
  GetSignInListRequest,
  GetSignInListResp,
  GetSignInRuleListRequest,
  GetSignInRuleListResp,
  GetSignIntaskListRequest,
  GetSignRemindRequest,
  GetSignRemindResp,
  PostSignInItem,
  PostSignInRequest,
  GetAllGuideMarkReq,
  GetAllGuideMarkResp,
  UpdateGuideMarkReq,
  GetSignInActivityRuleReq,
  GetSignInActivityRuleResp,
  GetSignIntaskItem,
} from '@/typings/api/marketing/sign-in'

/**
 * 查询签到活动信息
 */
export const GetSignInInfo: MarkcoinRequest<GetSignInInfoRequest, GetSignInInfoResp> = params => {
  return request({
    path: '/v1/activity/sign/info',
    method: 'GET',
    params,
  })
}

/**
 * 签到
 */
export const PostUserSignIn: MarkcoinRequest<PostSignInRequest, PostSignInItem[]> = data => {
  return request({
    path: '/v1/activity/sign/submit',
    method: 'POST',
    data,
  })
}

/**
 * 查询签到任务列表
 */
export const GetSignIntaskList: MarkcoinRequest<GetSignIntaskListRequest, GetSignIntaskItem[]> = params => {
  return request({
    path: '/v1/activity/sign/task/list',
    method: 'GET',
    params,
  })
}

/**
 * 查询签到周期列表
 */
export const GetSignInList: MarkcoinRequest<GetSignInListRequest, GetSignInListResp> = params => {
  return request({
    path: '/v1/activity/sign/period',
    method: 'GET',
    params,
  })
}

/**
 * 查询签到活动规则
 */
export const GetSignInRules: MarkcoinRequest<GetSignInRuleListRequest, GetSignInRuleListResp> = params => {
  return request({
    path: '/v1/activity/sign/desc',
    method: 'GET',
    params,
  })
}

/**
 * 设置签到提醒
 */
export const GetSignRemind: MarkcoinRequest<GetSignRemindRequest, GetSignRemindResp> = params => {
  return request({
    path: '/v1/activity/sign/remind',
    method: 'GET',
    params,
  })
}

export const getSignInActivityRule: MarkcoinRequest<GetSignInActivityRuleReq, GetSignInActivityRuleResp> = params => {
  return request({
    path: '/v1/activity/sign/desc',
    method: 'GET',
    params,
  })
}

/**
 * 查询所有的引导图状态
 */
export const getAllGuideMark: MarkcoinRequest<GetAllGuideMarkReq, GetAllGuideMarkResp> = params => {
  return request({
    path: '/v1/guideMap/queryAll',
    method: 'GET',
    params,
  })
}

/**
 * 更新引导图状态
 */
export const updateGuideMark: MarkcoinRequest<UpdateGuideMarkReq, void> = params => {
  return request({
    path: '/v1/guideMap/update',
    method: 'POST',
    data: params,
  })
}
