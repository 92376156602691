/**
 * 签到活动
 */

import { t } from '@lingui/macro'

/** 签到提醒 */
export enum SignRemaindEnum {
  /** 开启 */
  open = 'open',
  /** 关闭 */
  close = 'close',
}

/** 规则类型枚举 */
export enum SignRuleTypeEnum {
  /** 首次签到 */
  first = 'first',
  /** 每日签到 */
  single = 'single',
  /** 连续签到 */
  continuity = 'continuity',
}

/** 获取规则类型 */
export function getSignRuleTypeName(type: SignRuleTypeEnum | string) {
  return {
    [SignRuleTypeEnum.first]: t`constants_marketing_sign_in_5hlnowqysx`,
    [SignRuleTypeEnum.single]: t`features_welfare_center_sign_in_activity_enter_index_xsrpdba3gd`,
    [SignRuleTypeEnum.continuity]: t`features_welfare_center_activities_center_components_calendar_index_vsnxoevvrw`,
  }[type]
}

/** 发放类型 */
export enum GivenTypeTypeEnum {
  /** 固定 */
  fixed = 'fixed',
  /** 随机 */
  random = 'random',
}

/** 获取规则类型 */
export function getGivenTypeTypeName(type: GivenTypeTypeEnum | string) {
  return {
    [GivenTypeTypeEnum.fixed]: t`fixed_rewards`,
    [GivenTypeTypeEnum.random]: t`random_rewards`,
  }[type]
}

/** 一周时间 */
export enum WeeksEnum {
  sun = 'sun',
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
}

/** 获取一周类型 */
export function getWeekTypeName(type: WeeksEnum | string) {
  return {
    [WeeksEnum.sun]: t`features_c2c_center_my_status_index_2222222225101411`,
    [WeeksEnum.mon]: t`features_c2c_center_my_status_index_2222222225101405`,
    [WeeksEnum.tue]: t`features_c2c_center_my_status_index_2222222225101406`,
    [WeeksEnum.wed]: t`features_c2c_center_my_status_index_2222222225101407`,
    [WeeksEnum.thu]: t`features_c2c_center_my_status_index_2222222225101408`,
    [WeeksEnum.fri]: t`features_c2c_center_my_status_index_2222222225101409`,
    [WeeksEnum.sat]: t`features_c2c_center_my_status_index_2222222225101410`,
  }[type]
}

/** 是否开启连续签到 */
export enum IsOpenSignContinueEnum {
  /** 是 */
  yes = 'Y',
  /** 否 */
  no = 'N',
}
