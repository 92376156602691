import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { ModalProps } from '@nbit/arco'
import { t } from '@lingui/macro'
import SignInActivityBaseModal from '../common-modal'

import styles from './index.module.css'

type OpenParams = {
  onOk?: () => void
}

type IRemindModalProps = {
  remindText?: string
}

export interface ExposeRef {
  open: (params: OpenParams) => void // 打开弹窗
}

const RemindModal = forwardRef<ExposeRef, IRemindModalProps>(function ({ remindText }, ref) {
  const [visible, setVisible] = useState(false)
  const openParamsRef = useRef<OpenParams>()

  const onModalClose = () => {
    setVisible(false)
  }
  const onModalOk = () => {
    onModalClose()
    openParamsRef.current?.onOk?.()
  }
  const modalProps: ModalProps = {
    className: styles['remind-dialog'],
    visible,
    cancelText: t`features_trade_spot_index_2510`,
    okText: t`features_marketing_sign_in_activity_remind_modal_index_kpcektfi5b`,
    onCancel: onModalClose,
    onOk: onModalOk,
  }
  const remindContent = remindText || t`features_marketing_sign_in_activity_remind_modal_index_rsxy6rfngs`

  /**
   * 这里使用 expose ref 的方式暴露 open 方法，收敛弹窗内部状态及渲染所需数据，保持使用方简洁
   */
  useImperativeHandle(
    ref,
    () => ({
      open: data => {
        openParamsRef.current = { ...data }
        setVisible(true)
      },
    }),
    []
  )

  return (
    <SignInActivityBaseModal {...modalProps}>
      <div>
        <div className="dialog-title">{t`features_marketing_sign_in_activity_remind_modal_index_t2yb86hz06`}</div>
        <div className="remind-text">{remindContent}</div>
      </div>
    </SignInActivityBaseModal>
  )
})

export default RemindModal
