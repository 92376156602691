import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Icon from '@/components/icon'
import LazyImage from '@/components/lazy-image'
import { GetSignInList } from '@/apis/marketing/sign-in'
import { getTimestamp, isSameDay } from '@/helper/date'
import { t } from '@lingui/macro'
import { getWeekTypeName, WeeksEnum } from '@/constants/marketing/sign-in'
import { Spin } from '@nbit/arco'
import { marketingImgUrl } from '@/constants/marketing/common'
import styles from './index.module.css'
/** 定义日期类型 */
type DateObject = {
  /** 日历日期 */
  day: number | string
  month: number
  year: number
  /** 是否是当天 */
  isToday?: boolean
  /** 是否是月当天之前 */
  isLastDay?: boolean
  /** 是否签到 */
  isSign?: boolean
  /** 是否是当月 */
  isCurrentMonth?: boolean
  /** 当日时间戳 */
  timeTemplate: number | string
  /** 是否是所选当月 */
  isNother?: boolean
}
/** 一周时间 */
const weeks = Object.keys(WeeksEnum)
/** 12 个月 */
const months = 12
/** 最少前三个月 */
const minMonths = -3
/** 最多后三个月 */
const maxMonths = 3
/** 当前日历 6 周时长 */
const sixWeeks = 42
export const imgUrl = `${marketingImgUrl}sign-in/`
/** 未来几个月 icon */
export const darkUrl = `${imgUrl}icon_image_dark_gold.png`
/** 已签到 icon */
export const signUrl = `${imgUrl}icon_have_sign_in.png`
/** 未签到 icon */
export const noSignUrl = `${imgUrl}icon_image_nosign.png`
/** 当月未签到 icon */
export const lightUrl = `${imgUrl}icon_image_light_gold.png`
/** 当月前:0/当月:1/当月后 :2 */
const currentType = {
  /** 所选月份上个月 */
  lastMonth: 0,
  /** 所选月份 */
  currentMonth: 1,
  /** 所选月份下个月 */
  nextMonth: 2,
}

function Calendar(props) {
  const { changeData, signId } = props
  /** 当前日期（获取当前年月日） */
  const currentDate = new Date()
  /** 当前日期（获取周几） */
  /** 当年 */
  const currentYear = currentDate.getFullYear()
  /** 当天 */
  const currentDay = currentDate.getDate()
  /** 当月 */
  const currentMonth = currentDate.getMonth()
  const [datesArray, setDatesArray] = useState<DateObject[]>([])
  /** 与当前月份相差值 */
  let [sumNumber, setSumNumber] = useState<number>(0)
  /** 切换某月 */
  const [changeMonth, setChangeMonth] = useState(currentMonth)
  /** 切换年 */
  const [changeYear, setChangeYear] = useState(currentYear)

  const [isCurrent, setIsCurrent] = useState<number>(currentType.currentMonth)
  const [positionListLoading, setPositionListLoading] = useState<boolean>(false)

  /** 查询签到周期列表 */
  const getDataSource = async (startDate, endDate, dataList) => {
    let param = {
      signId,
      startDate,
      endDate,
    }
    let { isOk, data } = await GetSignInList(param)
    if (isOk) {
      changeData(data)
      data &&
        data?.signedList?.length !== 0 &&
        dataList.forEach(dataObj => {
          data?.signedList?.forEach(item => {
            // 检查是否是签到日期，在日期对象中设置已签到属性
            if (isSameDay(dataObj.timeTemplate, item)) {
              dataObj.isSign = true
            }
          })
        })
    }
    setDatesArray(dataList)
    setPositionListLoading(false)
  }
  const getNewDataList = (num, year, month) => {
    // 计算日期数组
    const dataList: DateObject[] = []
    /** 所选月份最后一天 */
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1 + sumNumber, 0)
    /** 所选月份上个月的最后一天 */
    const lastDayOfLastMonth = new Date(currentYear, currentMonth + sumNumber, 0)
    /** 当前选择月份上个月 */
    const selectLastMonth = month - 1
    /** 是否是去年 */
    const isLastYear = selectLastMonth === 0
    /** 当前选择年份上一年 */
    const selectLastYear = year - 1
    /** 当前选择月份下个月 */
    const selectNextMonth = month + 1
    /** 当前选择年份下一年 */
    const selectNextYear = year + 1
    // 添加所选月份上个月的最后两几天
    for (let i = 0; i < Number(num); i += 1) {
      dataList.push({
        day: lastDayOfLastMonth.getDate() - i,
        month: lastDayOfLastMonth.getMonth(),
        year: lastDayOfLastMonth.getFullYear(),
        isNother: true,
        isLastDay: true,
        timeTemplate: getTimestamp(
          `${isLastYear ? selectLastYear : year}-${isLastYear ? months : selectLastMonth}-${
            lastDayOfLastMonth.getDate() - i
          }`
        ).toString(),
      })
    }
    dataList.reverse()
    // 添加当前所选月份的所有日期
    for (let i = 1; i <= lastDayOfMonth.getDate(); i += 1) {
      dataList.push({
        day: i,
        month: currentMonth,
        year: currentYear,
        isToday: i === currentDay && currentMonth + 1 === month,
        isLastDay: i < currentDay || sumNumber < 0,
        isCurrentMonth: currentMonth + 1 === month,
        timeTemplate: getTimestamp(`${isLastYear ? selectLastYear : year}-${month}-${i}`).toString(),
      })
    }
    /** 日历总天数 */
    let allLength = sixWeeks
    // 添加所选月份下个月的前几天
    const remainingDays = allLength - dataList.length
    for (let i = 1; i <= remainingDays; i += 1) {
      dataList.push({
        day: i,
        month: currentMonth + 1,
        isNother: true,
        isLastDay: month < currentMonth + 1 && sumNumber < 0 && i < currentDay,
        isCurrentMonth: currentMonth === month,
        isToday: i === currentDay && currentMonth === month,
        year: currentYear,
        timeTemplate: getTimestamp(
          `${selectNextMonth > months ? selectNextYear : year}-${selectNextMonth > months ? 1 : selectNextMonth}-${i}`
        ).toString(),
      })
    }
    let startDate = dataList[0]?.timeTemplate
    // 获取数据结束时间
    let endDate = dataList[allLength - 1]?.timeTemplate

    getDataSource(startDate, endDate, dataList)
  }

  // 获取前 n 个月的第一天是周几
  function getDayOfWeekOfFirstDayOfPreviousMonth(n) {
    const today = new Date() // 获取当前日期
    const targetDate = new Date(today.getFullYear(), today.getMonth() + n, 1) // 计算目标日期

    return targetDate.getDay() // 返回目标日期是星期几，0 表示星期日，1 表示星期一，以此类推
  }
  useEffect(() => {
    // 切换月份时，判断是上个月，下个月，还是当月
    setPositionListLoading(true)
    let boolData =
      sumNumber > currentType.currentMonth
        ? currentType.nextMonth
        : sumNumber < currentType.currentMonth
        ? currentType.currentMonth
        : currentType.lastMonth
    setIsCurrent(boolData)
    let num = getDayOfWeekOfFirstDayOfPreviousMonth(sumNumber)
    // 当 sumNumber 变化的时候，计算当前年份
    let year = currentYear + Math.floor((currentMonth + sumNumber) / months)
    setChangeYear(year)
    // 当 sumNumber 变化的时候，计算当前月份
    let month = (currentMonth + 1 + sumNumber) % months === 0 ? months : (currentMonth + sumNumber + 1) % months

    setChangeMonth(month)
    getNewDataList(num, year, month)
  }, [sumNumber])
  return (
    <div>
      <div className="top-month">
        <div className="w-4">
          <Icon
            onClick={() => {
              if (sumNumber === minMonths) return
              setSumNumber((sumNumber -= 1))
            }}
            name="next_arrow"
            className={classNames('rotate-180', { hidden: sumNumber === minMonths })}
          />
        </div>
        <div className="date-text">
          {changeYear} {t`features_welfare_center_activities_center_components_calendar_calender_hwj51nxgkb`}{' '}
          {changeMonth < 10 ? `0${changeMonth}` : changeMonth}{' '}
          {t`features_welfare_center_activities_center_components_calendar_calender_mdiny6xro0`}
        </div>
        <div className="w-4">
          <Icon
            onClick={() => {
              if (sumNumber === maxMonths) return
              setSumNumber((sumNumber += 1))
            }}
            name="next_arrow"
            className={classNames({ hidden: sumNumber === maxMonths })}
          />
        </div>
      </div>
      <div className="weeks-box">
        {weeks.map((item, index) => (
          <div className="item-week" key={index}>
            {getWeekTypeName(item)}
          </div>
        ))}
      </div>
      {positionListLoading ? (
        <Spin loading={positionListLoading} />
      ) : (
        <div className={styles.calender}>
          <div className="inner-calendar">
            {datesArray.map((date, index) => (
              <div key={index} className={classNames('calendar-day', {})}>
                <div
                  className={classNames('inner-box', {
                    'nother-month':
                      (date?.isCurrentMonth && !date?.isToday && !date?.isLastDay) ||
                      (sumNumber > 0 && !date?.isNother),
                    'today-box': date?.isToday && currentMonth + 1 === changeMonth,
                    'nother-box': date?.isNother,
                  })}
                >
                  <div className="item-top">
                    {sumNumber > 0 ? (
                      <LazyImage
                        src={date?.isNother ? darkUrl : lightUrl}
                        className={classNames('sign-in', { 'sign-in-gold': !date.isSign })}
                      />
                    ) : sumNumber < 0 ? (
                      <LazyImage
                        src={
                          date?.isCurrentMonth
                            ? date?.isToday
                              ? `${signUrl}`
                              : date?.isLastDay
                              ? date?.isSign
                                ? `${signUrl}`
                                : `${noSignUrl}`
                              : `${lightUrl}`
                            : date?.isSign
                            ? `${signUrl}`
                            : `${noSignUrl}`
                        }
                        className="sign-in"
                      />
                    ) : (
                      <LazyImage
                        src={
                          date?.isCurrentMonth
                            ? date?.isLastDay
                              ? date?.isSign
                                ? `${signUrl}`
                                : `${noSignUrl}`
                              : date?.isToday
                              ? `${signUrl}`
                              : `${lightUrl}`
                            : !date?.isLastDay
                            ? `${darkUrl}`
                            : date?.isSign
                            ? `${signUrl}`
                            : `${noSignUrl}`
                        }
                        className="sign-in"
                      />
                    )}
                  </div>
                  <div
                    className={classNames('normal-day', {
                      'today': date?.isToday && currentMonth + 1 === changeMonth,
                      'supplementary-signature-text':
                        date?.isLastDay && date?.isSign && isCurrent === currentType.lastMonth,
                    })}
                  >
                    {date?.isToday
                      ? t`features_welfare_center_activities_center_components_calendar_calender__xf3lgt78j`
                      : date.day}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Calendar
