import { t } from '@lingui/macro'
import { Step } from 'intro.js-react'
import { IntroSteps } from '@/components/intro-js/intro-steps'

import styles from './index.module.css'

interface IActivityEntryIntroProps {
  /** 是否展示引导 */
  visible: boolean
  /** 高亮目标元素 */
  element: Step['element']
  onExit?: () => void
  onCompleted?: () => void
}

function ActivityEntryIntro({ visible, element, onExit, onCompleted }: IActivityEntryIntroProps) {
  const introSteps: Step[] = [
    {
      element,
      position: 'bottom',
      title: t`features_marketing_sign_in_activity_activity_entry_intro_index_2yv8qwgdwx`,
      intro: t`features_marketing_sign_in_activity_activity_entry_intro_index_ttpitywbap`,
    },
  ]

  return (
    <IntroSteps
      steps={introSteps}
      onExit={() => onExit?.()}
      stepEnabled={visible}
      onComplete={() => onCompleted?.()}
      tooltipClassCustom={styles['sign-in-entry-tooltip']}
      highlightClassCustom={styles['sign-in-entry-highlight']}
      options={{
        showBullets: false,
        showProgress: false,
        doneLabel: t`features_trade_spot_index_2510`,
        exitOnOverlayClick: false,
        hintAnimation: true,
      }}
    />
  )
}

export default ActivityEntryIntro
