import { useEffect, useRef, useState } from 'react'
import { GetSignInInfoResp } from '@/typings/api/marketing/sign-in'
import useSignInActivity from '@/hooks/features/marketing/use-sign-in-activity'
import SignInActivityModal from '../calendar'
import ActivityEntryIntro from '../activity-entry-intro'
import RemindModal, { ExposeRef } from '../remind-modal'

interface ISignInEntryBaseProps {
  children: (openSignIn: () => void, hasSignIn: boolean, activity?: GetSignInInfoResp) => React.ReactNode
  /** 签到指引高亮元素 ID */
  introElId: string
  /** 是否显示签到提醒 */
  showRemind?: boolean
  /** 是否显示签到指引 */
  showIntro?: boolean
}

function SignInEntryBase({ children, introElId, showRemind = true, showIntro = true }: ISignInEntryBaseProps) {
  const remindModalRef = useRef<ExposeRef>(null)
  const [showSignInModal, setShowSignInModal] = useState<boolean>(false)
  const { activiy, hasActivity, shouldShowIntro, shouldRemind, hasSignIn, closeIntro, closeRemind } =
    useSignInActivity()

  const finalShowIntro = shouldShowIntro && showIntro
  const openSignInModal = () => {
    setShowSignInModal(true)
    closeRemind()
    closeIntro()
  }
  const onSignModalClose = () => {
    setShowSignInModal(false)
  }
  const triggerTarget = children(openSignInModal, hasSignIn, activiy)

  useEffect(() => {
    if (shouldRemind && showRemind && !finalShowIntro) {
      remindModalRef.current?.open({
        onOk: openSignInModal,
      })
    }
  }, [shouldRemind, showRemind, finalShowIntro, remindModalRef.current])

  if (!hasActivity) return null

  return (
    <span>
      <span>{triggerTarget}</span>
      {finalShowIntro && (
        <ActivityEntryIntro visible element={`#${introElId}`} onCompleted={closeIntro} onExit={closeIntro} />
      )}
      <RemindModal remindText={activiy?.tips} ref={remindModalRef} />
      {showSignInModal && <SignInActivityModal visible={showSignInModal} onClose={onSignModalClose} />}
    </span>
  )
}

export default SignInEntryBase
