import React, { useEffect, useState } from 'react'
import { Button, Message, Switch } from '@nbit/arco'
import { useThrottleFn, useDebounceFn } from 'ahooks'
import Icon from '@/components/icon'
import AssetsPopUp from '@/features/assets/common/assets-popup'
import { t } from '@lingui/macro'
import LazyImage from '@/components/lazy-image'
import { PostUserSignIn, GetSignInInfo, GetSignIntaskList, GetSignRemind } from '@/apis/marketing/sign-in'
import ActivityRulesModal from '@/features/marketing/sign-in-activity/activity-rules-modal'
import ConfirmModal from '@/features/marketing/sign-in-activity/confirm-modal'
import { useSignInActivityStore } from '@/store/marketing/sign-in-activity'
import { EmptyStatePlaceholder } from '@/constants/base'
import { GetSignInListResp, GetSignIntaskItem, PostSignInItem } from '@/typings/api/marketing/sign-in'
import {
  SignRemaindEnum,
  SignRuleTypeEnum,
  getSignRuleTypeName,
  getGivenTypeTypeName,
  IsOpenSignContinueEnum,
  GivenTypeTypeEnum,
} from '@/constants/marketing/sign-in'
import { isToday } from '@/helper/date'
import { formatCurrency } from '@/helper/decimal'
import styles from './index.module.css'
import Calendar, { imgUrl } from './calender'
// 定义日期类型
type ModalObject = {
  visible: boolean
  /** 关闭弹框 */
  onClose?: () => void
}

const doubleLine = EmptyStatePlaceholder.doubleLine

function CalendarModal(props: ModalObject) {
  const [taskList, setTaskList] = useState<GetSignIntaskItem[]>([])
  const [periodList, setPeriodist] = useState<GetSignInListResp>()
  const [isRemaind, setIsRemaind] = useState<boolean>(false)
  const [isRequest, setIsRequest] = useState<boolean>(false)
  const [signInfo, setSignInfo] = useState<PostSignInItem>()
  const [signInInfo, setSignInInfo] = useState<PostSignInItem[]>()
  const {
    showTips,
    setShowTips,
    showSignTips,
    setShowSignTips,
    showSignTip,
    setShowSignTip,
    setHasSignIn,
    activiy,
    setActivity,
  } = useSignInActivityStore()
  const { visible, onClose } = props

  // 获取签到任务列表
  const getTasklist = async signId => {
    let param = {
      signId,
    }
    let res = await GetSignIntaskList(param)
    if (res?.isOk && res?.data) {
      setTaskList(res?.data || [])
    }
  }

  // 获取签到任务信息
  const getSignData = async () => {
    let res = await GetSignInInfo({})
    if (res?.isOk && res?.data?.id) {
      setActivity(res?.data)
      getTasklist(res?.data?.id)
      setIsRemaind(res?.data?.remindStatus === SignRemaindEnum.open)
    }
  }
  // 签到
  const signIn = async id => {
    let param = {
      signId: id,
    }
    let { isOk, data } = await PostUserSignIn(param).finally(() => {
      setIsRequest(true)
    })

    if (isOk && data) {
      setSignInInfo(data)
      let singleData = data?.filter(item => item?.ruleType === SignRuleTypeEnum.single) as PostSignInItem
      setSignInfo(singleData)
      setShowSignTips(true)
      setHasSignIn(true)
      // 签到成功之后更新签到活动信息
      getSignData()
    }
  }

  useEffect(() => {
    if (visible) {
      if (isToday(activiy?.lastSignDate || '')) {
        getSignData()
        setIsRequest(true)
        return
      }
      signIn(activiy?.id)
    }
  }, [visible])

  // 签到提醒接口
  const requestSignRemind = async e => {
    let param = {
      status: e ? SignRemaindEnum.open : SignRemaindEnum.close,
    }
    let { isOk, data } = await GetSignRemind(param)
    if (isOk && data?.isSuccess) {
      !e &&
        Message.info({
          content: t`features_ternary_option_plan_delegation_index_qcocatziz7`,
          showIcon: false,
          className: 'blackStyle',
        })
      e &&
        Message.info({
          content: t`features_welfare_center_activities_center_components_calendar_index_c2bqrjal0h`,

          showIcon: false,
          className: 'blackStyle',
        })
      setIsRemaind(e)
    }
    setShowSignTip(false)
  }
  // 开启关闭签到提醒
  const remaindSign = async e => {
    if (!e) {
      setShowSignTip(true)
      return
    }
    requestSignRemind(e)
  }
  // 使用 useThrottleFn 对处理点击事件的回调函数进行防抖处理
  const { run, cancel } = useThrottleFn(remaindSign, { wait: 1000 })

  const cancelTips = () => {
    requestSignRemind(false)
  }
  // 使用 useThrottleFn 对处理点击事件的回调函数进行防抖处理
  const { run: cancelTip } = useDebounceFn(cancelTips, { wait: 500 })

  const changeData = e => {
    !periodList && setPeriodist(e)
  }

  // 类型是固定 取 baseQuantity，随机取 baseQuantity～maxQuantity
  const getGivenType = item => {
    const data =
      item?.givenType === GivenTypeTypeEnum.fixed
        ? `${item?.baseQuantity}`
        : `${item?.baseQuantity}~${item?.maxQuantity}`
    return data
  }

  return (
    <AssetsPopUp
      onCancel={onClose}
      className={styles['sign-modal']}
      title={t`features_marketing_sign_in_activity_reward_dialog_index_4mv2aaaszm`}
      footer={null}
      visible={visible}
    >
      <div className="out-box">
        <div className="top-content">
          <div className="left">
            <div className="get-amont">
              {t`features_welfare_center_activities_center_components_calendar_index_pkhh5qklb_`}
              <span className="text-buy_up_color font-medium">
                {` ${(periodList?.todayAwards && periodList?.todayAwards[0]?.quantity) || 0} `}
                {(periodList?.todayAwards && periodList?.todayAwards[0]?.symbol) || doubleLine}
              </span>
            </div>
            <div className="sign-days">
              <div className="all-sing-days">
                {t`features_welfare_center_activities_center_components_calendar_index_18pycgujz8`}
                <span className="text-brand_color">{` ${periodList?.continuityDays || doubleLine} `}</span>
                {t`features_copy_trading_user_center_common_user_base_info_index_6ykrfimmic`}
              </div>
            </div>
            {activiy?.isOpenContinuity === IsOpenSignContinueEnum.yes && (
              <div className="last-day">
                {t`features_welfare_center_activities_center_components_calendar_index_yoxswoo75x`}
                {` ${periodList?.againDays || doubleLine} `}
                {t`features_welfare_center_activities_center_components_calendar_index_1qkj7pbjbw`}
              </div>
            )}
          </div>
          <div className="right">
            <div className="rule" onClick={() => setShowTips(true)}>
              <div>{t`features_agent_apply_index_5101498`}</div>
              <Icon name="next_arrow" />
            </div>
            <div className="tips-box">
              <div className="sign-tip">{t`features_marketing_sign_in_activity_remind_modal_index_t2yb86hz06`}</div>
              <Switch size="small" checked={isRemaind} onChange={run} />
            </div>
          </div>
        </div>
        <div className="calendar-box">
          {activiy?.id && isRequest && <Calendar changeData={changeData} signId={activiy?.id} />}
        </div>
        <div className="bottom-content">
          {activiy?.isOpenContinuity === IsOpenSignContinueEnum.yes && (
            <div className="bottom-item">
              <div className="content-title">{t`features_welfare_center_activities_center_components_calendar_index_vor5eekvhv`}</div>
            </div>
          )}
          {activiy?.isOpenContinuity === IsOpenSignContinueEnum.no
            ? null
            : taskList?.map((item, index) => (
                <div className="bottom-item" key={index}>
                  <div className="item-left">
                    <div className="icon-bg">
                      <Icon name="web_icon_continuous" />
                    </div>
                    <div className="item-left-content">
                      <div className="left-title">
                        {t`features_welfare_center_activities_center_components_calendar_index_vsnxoevvrw`}{' '}
                        {`${item?.days} `}
                        {t`features_copy_trading_user_center_common_user_base_info_index_6ykrfimmic`}
                      </div>
                      <div className="left-desc">
                        {t`features_welfare_center_activities_center_components_calendar_index_tsiviagddx`}
                        {` ${getGivenType(item)} ${item?.symbol || doubleLine}`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div>
          <div className="close-bt" onClick={onClose}>
            {t`features_trade_spot_index_2510`}
          </div>
        </div>
      </div>
      {/* 规则提示 */}
      <ActivityRulesModal visible={showTips} signId={activiy?.id} onCancel={() => setShowTips(false)} />
      {/* 签到成功提示 */}
      <AssetsPopUp
        style={{ width: 360 }}
        className={`${styles['sign-tips-modal']}`}
        footer={null}
        visible={showSignTips}
        title={t`features_marketing_sign_in_activity_reward_dialog_index_4mv2aaaszm`}
        onCancel={() => {
          setShowSignTips(false)
        }}
      >
        <div className="content-box">
          <LazyImage src={`${imgUrl}icon_image_light_gold.png`} className="sign-in-gold" />
          {signInInfo && signInInfo.length !== 0 ? (
            signInInfo.map((item, index) => (
              <div key={index} className="desc">
                <div>
                  <span>{`${t`features_welfare_center_activities_center_components_calendar_index_ax95oyumft`}${getSignRuleTypeName(
                    item?.ruleType || ''
                  )}${getGivenTypeTypeName(
                    item?.givenType || ''
                  )}${t`features_welfare_center_activities_center_components_calendar_index_9djwlobchm`}, `}</span>
                  <span className="text-buy_up_color">
                    +{formatCurrency(item?.quantity, 2)} {item?.symbol}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="desc-content">
              <div className="text-buy_up_color">+0.00 {signInfo?.symbol}</div>
              <div className="desc-text">{t`no_reward`}</div>
            </div>
          )}
          <Button
            type="primary"
            className={'bottom-bt'}
            onClick={() => {
              setShowSignTips(false)
            }}
          >
            {signInInfo && signInInfo.length !== 0
              ? t`features_marketing_sign_in_activity_reward_dialog_index_nmo0lccthj`
              : t`features_trade_spot_index_2510`}
          </Button>
        </div>
      </AssetsPopUp>
      {/* 取消签到提醒 */}
      <ConfirmModal visible={showSignTip} onCancel={() => setShowSignTip(false)} onOk={cancelTip} />
    </AssetsPopUp>
  )
}

export default CalendarModal
