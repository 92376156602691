/**
 * 提醒开启状态
 */
export enum RemindStatusEnum {
  open = 'open',
  close = 'close',
}

/**
 * 签到引导状态
 */
export enum GuideStatusEnum {
  /** 展示引导 */
  open = '0',
  /** 不展示引导 */
  close = '1',
}

/**
 * 签到引导key
 */
export const SignInGuideKey = 'sign_guide'

/**
 * 通用是或否
 */
export enum CommonStatusEnum {
  yes = 'Y',
  no = 'N',
}
