import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { ModalProps, Spin } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { getSignInActivityRule } from '@/apis/marketing/sign-in'
import SignInActivityBaseModal from '../common-modal'

import styles from './index.module.css'

interface IActivityRulesModalProps {
  visible: boolean
  signId?: number
  onCancel: () => void
}

function ActivityRulesModal({ visible, signId, onCancel }: IActivityRulesModalProps) {
  const [ruleContent, setRuleContent] = useState<string>('')
  const { runAsync: fetchRuleInfo, loading } = useRequest(getSignInActivityRule, { manual: true })

  const modalProps: ModalProps = {
    className: styles['sign-in-rules-modal'],
    okText: ruleContent ? t`features_trade_spot_index_2510` : '',
    visible,
    onCancel,
    onOk: onCancel,
  }
  const requestRuleInfo = async (id: number) => {
    try {
      setRuleContent('')
      const res = await fetchRuleInfo({ signId: id })
      setRuleContent(res?.data?.desc || '')
    } catch (error) {
      /***/
    }
  }

  useEffect(() => {
    if (visible && signId) {
      requestRuleInfo(signId)
    }
  }, [signId, visible])

  return (
    <SignInActivityBaseModal {...modalProps}>
      <div>
        <div className="dialog-title">{t`features_marketing_sign_in_activity_rule_modal_index_mhcirtcjc2`}</div>
        <Spin loading={loading} className="rule-spin">
          <div
            className="rule-content"
            dangerouslySetInnerHTML={{
              __html: ruleContent,
            }}
          ></div>
        </Spin>
      </div>
    </SignInActivityBaseModal>
  )
}

export default ActivityRulesModal
