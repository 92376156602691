import { Button, Modal, ModalProps } from '@nbit/arco'
import cs from 'classnames'

import styles from './index.module.css'

interface IBaseModalProps extends ModalProps {
  children?: React.ReactNode
}

function SignInActivityBaseModal({
  children,
  cancelText,
  okText,
  cancelButtonProps,
  okButtonProps,
  className,
  onCancel,
  onOk,
  ...others
}: IBaseModalProps) {
  return (
    <Modal className={cs(styles['sign-in-dialog-base'], className)} {...others} onCancel={onCancel} footer={null}>
      <div>{children}</div>
      <div className="dialog-footer">
        {cancelText && (
          <Button {...cancelButtonProps} onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {okText && (
          <Button {...okButtonProps} onClick={() => onOk?.()} type="primary">
            {okText}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default SignInActivityBaseModal
