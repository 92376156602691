import { ModalProps } from '@nbit/arco'
import { t } from '@lingui/macro'
import SignInActivityBaseModal from '../common-modal'

import styles from './index.module.css'

interface IConfirmModalProps {
  visible: boolean
  onCancel?: () => void
  onOk?: () => void
}

function ConfirmModal({ visible, onCancel, onOk }: IConfirmModalProps) {
  const modalProps: ModalProps = {
    className: styles['sign-in-confirm-modal'],
    visible,
    okText: t`user.field.reuse_17`,
    cancelText: t`trade.c2c.cancel`,
    closable: false,
    maskClosable: false,
    onCancel,
    onOk,
  }

  return (
    <SignInActivityBaseModal {...modalProps}>
      <div>
        <div className="dialog-title">
          {t`features_marketing_sign_in_activity_close_confirm_modal_index_vishxx05cw`}
        </div>
        <div className="confirm-text">
          {t`features_marketing_sign_in_activity_close_confirm_modal_index_neh4tlut8u`}
        </div>
      </div>
    </SignInActivityBaseModal>
  )
}

export default ConfirmModal
